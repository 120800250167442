import React, { useEffect, useRef, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import "./calendar.scss";
import { Mixpanel } from "../../mixpanel";
import { isMobile } from "react-device-detect";
import { useHistory, useParams } from "react-router-dom";
import useCampaign from "./useCampaign";
import { getConcatenatedStringOfFeaturesForAnalytics } from "../../abTesting";
import useUTMParams from "./useUTMParams";

const Calendar = (props) => {
  const iframeRef = useRef();
  const history = useHistory();
  const [showButtons, setShowButtons] = useState(false);

  const { handleEvent, getCampaignValue, getSourceType, campaignName } =
    useCampaign(props);

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log(""),
    onDateAndTimeSelected: () => console.log(""),
    onEventTypeViewed: () => console.log(""),
    onEventScheduled: (e) => handleEvent(),
  });

  const getUrl = () => {
    // debugger;
    if (props.calendlyLink) {
      return props.calendlyLink;
    } else if (props["company-page"]) {
      return "https://calendly.com/ofra-erez/15min?hide_event_type_details=1&hide_gdpr_banner=1&month=2025-02";
    } else if (props.companyId) {
      return "https://calendly.com/d/ykb-5gr-r88/better?hide_event_type_details=1&hide_gdpr_banner=1&month=2025-02";
    }

    return "https://calendly.com/d/d56-qkn-h6z/better?hide_event_type_details=1&hide_gdpr_banner=1&month=2025-02";
  };

  const getStyles = () => {
    let styles = {};
    if (isMobile) styles = { height: "80vh", width: "100%" };
    else styles = { height: "1000px", width: "610px" };
    if (props.styles) styles = { ...styles, ...props.styles };
    return styles;
  };

  const showCalendarButtons = () => {
    setShowButtons(true);
  };
  const utm = {
    utmCampaign: getSourceType(),
    utmContent: Mixpanel.getId(),
    utmMedium: getConcatenatedStringOfFeaturesForAnalytics([
      "landingPageVersion",
      "mainHomeImage",
    ]),
    utmSource: getCampaignValue(),
    utmTerm: campaignName,
  };
  console.log(utm);

  const utmPararms = useUTMParams();
  console.log("utmPararms", utmPararms);
  if (props.calendarOnly) {
    return (
      <div id="zoom-schedule">
        <InlineWidget
          styles={getStyles()}
          url={getUrl()}
          pageSettings={{
            backgroundColor: "ffffff",
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: "00a2ff",
            textColor: "4d5055",
          }}
          utm={{
            utmCampaign: getSourceType(),
            utmContent: Mixpanel.getId(),
            utmMedium: getConcatenatedStringOfFeaturesForAnalytics([
              "landingPageVersion",
              "mainHomeImage",
            ]),
            utmSource: getCampaignValue(),
            utmTerm: campaignName,
            salesforce_uuid: JSON.stringify(utmPararms),
          }}
        />
      </div>
    );
  }
  return (
    <section id="zoom">
      <div className="calendar-container">
        <div className="row container">
          <div className="calendar-information  col-6 ">
            <h2 className="title">
              {props["company-page"]
                ? "האם זה מתאים לחברה שלכם?"
                : "תיאום Zoom היכרות"}
            </h2>
            {props["company-page"] ? (
              <p>
                בחרו את המועד הנוח ביותר לשיחה בת 30 דקות, אנו נשלח לכם זימון
                למייל ונתקשר מולכם בוואטסאפ.
              </p>
            ) : (
              <p>
                בחרו את המועד הנוח ביותר לשיחה בת 30 דקות עם מומחי הפיננסים
                שלנו.
                <br /> אנו נשלח לכם זימון במייל ונתקשר מולכם בוואטסאפ
              </p>
            )}
            {/* old implementation */}
            {/* <div className="no-good-time">לא מצאתם מועד שמתאים לכם? {!showButtons && <span className="click" onClick={showCalendarButtons} >לחצו כאן</span>}</div>
                        {showButtons && <div className="calendar-buttons">
                            <button onClick={()=>history.push('/noConvenientTime')}>אין מועד שמתאים לי</button>

                            <button onClick={()=>history.push('/meetingToday')}>רוצה להיפגש היום</button>

                            <button onClick={()=>history.push('/moreInfo')}>רוצה לקבל מידע נוסף</button>
                        </div>} */}

            {!isMobile && (
              <img src="/images/all/QANDA-Calendar.png" alt="circular" />
            )}
          </div>
          <div className="calendar-template col-6">
            <div className="Calendar" id="zoom-schedule">
              <InlineWidget
                styles={getStyles()}
                url={getUrl()}
                pageSettings={{
                  backgroundColor: "ffffff",
                  hideEventTypeDetails: false,
                  hideLandingPageDetails: false,
                  primaryColor: "00a2ff",
                  textColor: "4d5055",
                }}
                utm={{
                  utmCampaign: getSourceType(),
                  utmContent: Mixpanel.getId(),
                  utmMedium: getConcatenatedStringOfFeaturesForAnalytics([
                    "landingPageVersion",
                    "mainHomeImage",
                  ]),
                  utmSource: getCampaignValue(),
                  utmTerm: campaignName,
                  salesforce_uuid: JSON.stringify(utmPararms),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Calendar;
